import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption";

import One from "../../../content/assets/candle-holders/1.jpg"
import Two from "../../../content/assets/candle-holders/2.gif"
import Three from "../../../content/assets/candle-holders/3.jpg"
import Four from "../../../content/assets/candle-holders/4.jpg"
import Five from "../../../content/assets/candle-holders/5.jpg"
import Six from "../../../content/assets/candle-holders/6.jpg"
import Seven from "../../../content/assets/candle-holders/7.jpg"

const CandleHolders = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const candleHolders = [
    {
      src: One,
      width: 1,
      height: 1.5,
      caption: `Modern Candle Holder, approximately 3 inches square.`
    },
    {
      src: Two,
      width: 1,
      height: 1.7,
      caption: `Candle Holder, 4 inches tall. Traditional blacksmith "baked" oil and wax finish.`,
    },
    {
      src: Three,
      width: 1,
      height: 1.5,
      caption: `Rush Light Candle Holder. 8 inches high`
    },
    {
      src: Four,
      width: 1,
      height: 1,
      caption: `Miner's Candle Holder, length 10 inches`
    },
    {
      src: Five,
      width: 1,
      height: 1.7,
      caption:
        'Colonial Style Candle Holder 20" high, 10" wide Baked on Oil and Wax Finish',
    },
    {
      src: Six,
      width: 1,
      height: 1.8,
      caption: `Colonial Style Candle Stand, 19 inches high Historic blacksmith's black "baked" oil and wax finish`
    },
    {
      src: Seven,
      width: 0.8,
      height: 2,
      caption: `Candle Holder & Rush Light`
    },
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Candle Holders</h1>
        <Gallery photos={candleHolders} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={candleHolders.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default CandleHolders
